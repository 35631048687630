<template>
  <div>
    <b-card title="EDITAR PEDIDO" class="text-center">
      <hr>
      <b-row class="mb-1">
        <b-col cols="6">
          <b-form-datepicker id="fecha-pedido" size="sm" placeholder="Fecha" v-model="complements.fecha"
            :date-format-options="{ year: '2-digit', month: '2-digit', day: '2-digit' }" local="es" />
        </b-col>
        <b-col cols="6">
          <b-form-input id="referencia" type="text" size="sm" v-model="complements.referencia" placeholder="Referencia"
            class="form-control-merge text-center" readonly/>
        </b-col>
      </b-row> 
      <b-row>
        <b-form-group class="mx-auto" size="sm">
          <b-form-radio-group v-model="complements.registro_tipo"
            :options="[{ text: 'Hueco luz', value: 1 }, { text: 'Hueco + Registro', value: 2 }, { text: 'Medida Total', value: 3 }]"
            plain class="" />
        </b-form-group>
      </b-row>
      <b-form-group v-if="complements.registro_tipo == 2 || complements.registro_tipo == 3" label-cols-md="4"
        label-size="sm" label="Medida Registro" label-for="registro-medida" class="mx-auto">
        <b-form-input id="registro-medida" size="sm" v-model="complements.registro_medida" type="number" />
      </b-form-group>           
      <b-row class="mx-3 mb-1">
        <b-col cols="5" class="text-center">Ancho</b-col>
        <b-col cols="2"></b-col>
        <b-col cols="5" class="text-center">Alto</b-col>
      </b-row>
      <b-row class="mx-3 mb-2">
        <b-col cols="5">
          <b-form-input
            id="width"
            type="number"
            v-model.number="dimensions.width"
            class="form-control-merge text-center"
            name="width"
            readonly
          />
        </b-col>
        <b-col cols="2" class="text-center">X</b-col>
        <b-col cols="5">
          <b-form-input
            id="height"
            type="number"
            v-model.number="dimensions.height"
            class="form-control-merge text-center"
            name="height"
            readonly
          />
        </b-col>
      </b-row>
      <hr>
        <b-row class="mb-2 mt-3">
          <b-col cols="4">Fabricante</b-col>
          <b-col cols="8">
            <v-select
              v-model="complements.fabricante"
              dir="ltr"
              label="title"
              :options="fabricante_options"
            />
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="4">Persiana</b-col>
          <b-col cols="8">
            <b-form-radio-group
              v-model="complements.persiana"
              :options="sino_options"
              name="persiana"
            />
          </b-col>
        </b-row>
        <b-row class="mb-2" v-if="complements.persiana">
          <b-col cols="4">Descripción Persiana</b-col>
          <b-col cols="8">
            <b-form-textarea
              id="persiana_desc"
              size="sm"
              name="persiana_desc"
              v-model="complements.persiana_desc"
              no-resize
            />
          </b-col>
        </b-row>
      <b-button type="button" variant="success" block @click="next"> Continuar </b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BRow,
  BFormTextarea,
  BFormDatepicker,
  BFormInput,
  BCardText,
  BButton,
  BFormRadioGroup,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    BCard,
    BCol,
    BRow,
    BFormTextarea,
    BFormDatepicker,    
    BCardText,
    BButton,
    BFormInput,
    BFormRadioGroup,
    BFormGroup,
    vSelect,    
  },
  data() {
    return {
      order : null,
      dimensions : {'width' : 0 , 'height' : 0, 'type' : 0},
      complements : {
        fecha: "",
        persiana: false,
        registro_medida : "",
        registro_tipo : 0,
        persiana_desc : "",
        referencia: "",        
        fabricante: null,
      },
      sino_options: [
        { text: "Si", value: true },
        { text: "No", value: false },
      ],
      fabricante_options: [],      
    };
  },
  async created() {
    await this.setOrder();
  },
  mounted() {
    this.$http.get("/api/getManufacturers").then((response) => {
         this.fabricante_options = response.data
    });
    this.$http.get("/api/getOrder?id=" + this.order).then((response) => {
        this.dimensions.width = response.data.frame_width;
        this.dimensions.height = response.data.frame_height;
        if (response.data.register) {
          this.complements.registro = true; 
          this.complements.registro_medida = response.data.registro_medida; 
          this.complements.registro_tipo = response.data.measure_type; 
        }else{
          this.complements.registro = false; 
        }
        if (response.data.blind) {
          this.complements.persiana = true; 
          this.complements.persiana_desc = response.data.persiana_desc; 
        }else{
          this.complements.persiana = false; 
        }
         this.complements.fecha = response.data.fecha
         this.complements.referencia = response.data.referencia
         this.complements.comments = response.data.observations
         this.complements.fabricante = {title : response.data.name, id : response.data.manufacturer_id }
         this.$store.commit('ventanas/setDimensions', {'width' : response.data.frame_width , 'height' : response.data.frame_height})
         this.$store.commit('ventanas/setComplements',this.complements)
    });
  },
  methods : {
    setOrder() {
      this.order = this.$route.query.id;
      this.$store.commit('ventanas/setOrder',this.order)
    },
    next() {
     if(this.dimensions.width < 1 || this.dimensions.height < 1) {
       this.$bvToast.toast('Las medidas introducidas no son las correctas', {variant: 'danger', title : 'Error' } );
       return 0;
     }
     if(this.complements.fabricante == null){
       this.$bvToast.toast('Debe introducir un fabricante', {variant: 'danger', title : 'Error' } );
       return 0;
     }
     this.$store.commit('ventanas/setDimensions',this.dimensions);
     this.$store.commit('ventanas/setComplements',this.complements)      
     this.$router.push("/elements_edit");

    }
  }
};
</script>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
</style>